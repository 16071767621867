<template>
    <button class="focus:ring-4 focus:outline-none font-medium text-sm text-center rounded-md
        text-neutral-50 hover:transition-all hover:duration-100" @click="emit('click')" :class="classColor">
        <slot />
    </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
    type?: "primary" | "green" | "red" | "blue" | "disabled",
    size?: "sm" | "md" | "lg"
}>(), {
    type: "primary",
    size: "md"
});

const emit = defineEmits<{
    'click': []
}>()

const classColor = computed(() => {
    const classes = [`btn-${props.type}`];
    if(props.size == 'sm'){
        classes.push('py-2', 'px-3')
    } else if(props.size == 'md'){
        classes.push('py-2.5', 'px-4')
    }
    return classes;
});
</script>

<style>
.btn-primary {
    @apply bg-gradient-to-l from-primary-700 to-primary-800 hover:from-primary-800 hover:to-primary-900 focus:ring-primary-900;
}

.btn-blue {
    @apply  bg-gradient-to-l from-blue-700 to-blue-800 hover:from-blue-800 hover:to-blue-900 focus:ring-blue-900;
}

.btn-red {
    @apply  bg-gradient-to-l from-red-700 to-red-800 hover:from-red-800 hover:to-red-900 focus:ring-red-900;
}

.btn-green {
    @apply bg-gradient-to-l from-green-700 to-green-800 hover:from-green-800 hover:to-green-900 focus:ring-green-900;
}

.btn-disabled {
    @apply text-neutral-400 bg-neutral-800 focus:ring-neutral-800 hover:cursor-not-allowed;
}
</style>