<template>
    <div class="
    absolute bottom-1/2 left-1/2 -translate-x-1/2 -transalte-y-1/2 z-50 shadow
    bg-neutral-700 text-white rounded-lg w-auto h-auto p-4 flex flex-col justify-between gap-3
    transition-all duration-1000 ease-in-out" v-show="visible">
        <span class="font-semibold text-base">
            {{ modalText }}
        </span>
        <div class="flex flex-row justify-end gap-2">
            <Button type="red" @click="noClicked">No</Button>
            <Button type="green" @click="yesClicked">Yes</Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useModalStore } from '@/stores/modal';
import { storeToRefs } from 'pinia';
import Button from './Button.vue';
const modalStore = useModalStore();

const { visible, modalText, yes_cb, no_cb } = storeToRefs(modalStore);

function noClicked() {
    visible.value = false;
    no_cb.value();
}

function yesClicked() {
    visible.value = false;
    yes_cb.value();
}
</script>