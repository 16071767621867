<template>
    <div v-if="authStore.loggedIn">
        <div class="fixed top-0 left-0 w-screen h-full z-[60] bg-black/70" @click="menuStore.notificationsMenu.open = false"
            v-show="menuStore.notificationsMenu.open">
        </div>
        <Transition name="fade">
            <div class="absolute top-0 right-0 h-full w-[max(65vw,240px)] bg-neutral-900 flex flex-col gap-2 p-4
            overflow-x-hidden overflow-y-scroll z-[61]" v-show="menuStore.notificationsMenu.open">
                <div class="text-center">
                    <div class="font-semibold text-sm uppercase text-neutral-300 space-x-2">
                        <span> {{ toastStore.allToasts.length }} Notifications</span>
                    </div>
                </div>

                <div v-if="toastStore.allToasts.length">
                    <div v-for="(toast, index) in toastStore.allToasts">
                        <span class="text-neutral-500 text-xs">{{ new Date(toast.ts).toLocaleTimeString([], {
                            hour:
                                '2-digit', minute: '2-digit'
                        }) }}</span>
                        <ToastContent :type="toast.type" class="select-none z-[99] notification py-1" :closeable="false"
                            v-show="toast.visible" :key="toast.message + index"> {{ toast.message }}
                        </ToastContent>
                    </div>

                    <div class="mt-4">
                        <Button class="w-full" @click="toastStore.allToasts.length = 0">
                            Clear all
                        </Button>
                    </div>
                </div>
                <div class="text-center py-4" v-else>
                    <span class="text-neutral-500">No notifications</span>
                </div>


            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import { useMenuStore } from '@/stores/menu';
import { useToastStore } from '@/stores/toast';
import ToastContent from '../core/ToastContent.vue';
import Button from '../core/Button.vue';

const authStore = useAuthStore();
const menuStore = useMenuStore();
const toastStore = useToastStore();

</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 250ms ease-in;
}

.slide-fade-leave-active {
    transition: all 100ms ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(240px);
    opacity: 0.6;
}
</style>