<template>
    <OfflineScreen v-if="isSupported && !isOnline" />
    <LoadingScreen v-else-if="authLoading" />
    <template v-else>
        <MenuLayout v-if="auth.loggedIn || refreshTokenNotExp">
            <router-view :key="route.fullPath" />
        </MenuLayout>
        <BasicLayout v-else-if="!['/login', '/register', '/select-username'].includes(route.path)">
            <router-view />
        </BasicLayout>
        <router-view v-else />
    </template>
</template>

<script setup lang="ts">
import MenuLayout from "./layouts/MenuLayout.vue";
import { useRoute } from "vue-router/auto";
import { useAuthStore } from "./stores/auth";
import BasicLayout from "./layouts/BasicLayout.vue";
import { computed, onMounted, ref } from "vue";
import LoadingScreen from "./components/core/LoadingScreen.vue";
import OfflineScreen from "./components/core/OfflineScreen.vue";
import { useNetwork } from "@vueuse/core";

const route = useRoute();
const auth = useAuthStore();

const { isSupported, isOnline } = useNetwork();

const authLoading = ref(false);

// onMounted(async () => {
    // await auth.handleLoginChanged(auth.loggedIn);
    // authLoading.value = false;
// });

const refreshTokenNotExp = computed(() => auth.refreshTokenInfo && auth.refreshTokenInfo.exp * 1000 > Date.now());
</script>