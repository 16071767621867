<template>
    <div class="tint" v-if="modalStore.visible"></div>
    <div class="p-1 md:py-0 md:px-2 m-auto max-w-[1920px]" :class="{'container max-w-screen-xl': !isWide}">
        <slot />
    </div>
    <Modal />
    <Toasts />
</template>

<script setup lang="ts">
import Modal from "@/components/core/Modal.vue";
import Toasts from "@/components/core/Toasts.vue";
import { computed } from 'vue';
import { useModalStore } from "@/stores/modal";
import { useRoute } from "vue-router/auto";
const modalStore = useModalStore();

const route = useRoute();
const WIDE_ROUTES = ['/play'];
const isWide = computed(() => WIDE_ROUTES.includes(route.path));

</script>

<style>
.tint:after {
    content: '';
    @apply bg-neutral-900 w-full h-full top-0 left-0 fixed z-[49] opacity-90 pointer-events-none;
}

.tint.dim:after {
    background: black;
    opacity: .6;
}
</style>