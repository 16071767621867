import { useGameStore } from '@/stores/game';
import { useAuthStore } from '@/stores/auth';
import { BACKEND_WS } from '@/constants';
import { useGeneralStore } from '@/stores/general';
import type { GameEvent, GameEvents, LobbyEvents, PlayerEvents, exp_declaration_t } from '@/server-types';
import { useToastStore } from '@/stores/toast';
import { useLobbyStore } from '@/stores/lobby';
import { router } from "@/router";
import { useLobbiesStore } from '@/stores/lobbies';
let ws: WebSocket;

let socketReconnect = false;
let socketLogged = false;
const queue: string[] = [];

export function sendCardPlayed(card: string) {
    send({ event: "Trick:CardPlayed", card });
}

export function sendBidSelected(bid: string) {
    send({ event: "Round:BidGamemode", bid });
}

export function submitDeclaration(declaration: exp_declaration_t) {
    send({ event: "Round:SubmitDeclaration", declaration });
}

export function subscribeToGame(uuid: string) {
    send({ event: "Games:Subscribe", uuid });
}

function send(obj: { event: string } & Record<string, any>) {
    console.log("sending", obj.event, socketLogged)
    if (ws != undefined && ws.readyState == ws.OPEN && (socketLogged || obj.event === 'User:Login')) {
        ws.send(JSON.stringify(obj))
    } else {
        console.log("ADD TO QUEUE", obj.event, socketLogged)
        queue.push(JSON.stringify(obj));
    }
}

function createWebsocket() {
    const gameStore = useGameStore();
    const auth = useAuthStore();
    const general = useGeneralStore();
    const toast = useToastStore();
    const lobby = useLobbyStore();
    const lobbies = useLobbiesStore();

    function handleEvent(name: string, msg: any) {
        if (name.startsWith("Game:")) {
            handleGameEvent(name.substring(5), msg)
        } else if (name.startsWith("Round:")) {
            handleRoundEvent(name.substring(6), msg)
        } else if (name.startsWith("Trick:")) {
            handleTrickEvent(name.substring(6), msg)
        } else if (name.startsWith("Player:")) {
            handlePlayersEvent(name.substring(7), msg);
        } else if (name.startsWith("User:")) {
            handleUserEvent(name.substring(5), msg);
        } else if (name.startsWith("Friends:")) {
            handleFriendsEvent(name.substring(8), msg);
        } else if (name.startsWith("Lobby:")) {
            handleLobbyEvents(name.substring(6), msg)
        }
    }

    function handleLobbyEvents(name: string, msg: any) {
        switch (name) {
            case 'Created':
                const created = msg as LobbyEvents.LobbyCreated;
                lobbies.onLobbyCreated(created.lobbyId, true);
                lobby.clearLobby();
                break;
            case 'Deleted':
                const deleted = msg as LobbyEvents.LobbyDeleted;
                lobbies.onLobbyDeleted(deleted.lobbyId);
                break;
            case 'PlayerAdded':
                const playerAdded = msg as LobbyEvents.LobbyPlayerAdded;
                toast.info(playerAdded.player + " added to lobby")
                lobby.update();
                lobbies.onPlayerAdded(playerAdded.lobbyId, playerAdded.teamId, playerAdded.player);
                break;
            case 'PlayerRemoved':
                const playerRemoved = msg as LobbyEvents.LobbyPlayerRemoved;
                lobby.update();
                lobbies.onPlayerRemoved(playerRemoved.lobbyId, playerRemoved.team, playerRemoved.player);
                break;
            case 'Invitation':
                const lobbyInvitation = msg as LobbyEvents.LobbyInvitation;
                lobby.receiveInvitation(lobbyInvitation.lobbyId)
                break;
            case 'ClearInvitations':
                lobby.clearInvitations();
                break;
        }
    }

    function handleUserEvent(name: string, msg: any) {
        switch (name) {
            case 'Login':
                console.log("Login successful, emptying the queue");
                socketLogged = true;
                while (queue.length > 0) {
                    const elem = queue.shift();
                    if (elem != undefined) {
                        ws!.send(elem)
                    }
                }
                break;
        }
    }

    function handleFriendsEvent(name: string, msg: any) {
        switch (name) {
            case "RequestReceived":
                toast.info("You have received a friend request from " + msg.username);
                auth.refresh();
                break;
            case "RequestAccepted":
                toast.info(msg.username + " has accepted your friend request");
                auth.refresh();
                break;
            case "FriendRemoved":
                toast.info(msg.username + " has unfriended you");
                auth.refresh();
                break;
        }
    }

    function handlePlayersEvent(name: string, msg: any) {
        switch (name) {
            case 'Online':
                const event = msg as PlayerEvents.Online;
                const { online, offline } = event;
                if (online != undefined) {
                    for (const username in online) {
                        general.online[username] = online[username];
                    }
                }
                if (offline != undefined) {
                    for (const username of offline) {
                        delete general.online[username];
                    }
                }
                break;
        }
    }

    function handleGameEvent(name: string, msg: any) {
        switch (name) {
            case 'Started':
                const gameStarted = msg as GameEvents.GameStarted;
                const myUsername = auth.username!;
                const order = gameStarted.order;
                const playing = order.includes(myUsername);
                const side = playing ? myUsername : order[0]!;

                gameStore.gameStarted(
                    msg.uuid,
                    side,
                    gameStarted.teamA.includes(auth.username!) ? 'A' : 'B',
                    gameStarted.order,
                    gameStarted.requiredPoints,
                    gameStarted.eloRange
                );

                if (playing) {
                    router.push("/play");
                }
                break;
            case 'Ended':
                const gameEnded = msg as GameEvents.GameEnded;
                gameStore.getGameHandler(msg.uuid)?.gameEnded({
                    newRating: gameEnded.newRating,
                    oldRating: auth.profile!.elo,
                    outcome: gameEnded.outcome
                });
                break;
            case 'StatePointsUpdate':
                const statePointsUpdate = msg as GameEvents.GameStatePointsUpdate;
                gameStore.getGameHandler(msg.uuid)?.updatePoints(statePointsUpdate.teamA, statePointsUpdate.teamB)
                break;
        }
    }

    function handleRoundEvent(name: string, msg: GameEvent) {
        const GameHandler = gameStore.getGameHandler(msg.uuid);
        if(!GameHandler) {
            console.log("received round event for unhandled game", msg)
            return;
        }
        switch (name) {
            case 'Started': {
                const started = msg as GameEvents.RoundStarted;
                GameHandler.roundStarted();
                break;
            }
            case 'Ended': {
                const ended = msg as GameEvents.RoundEnded;
                GameHandler.roundEnded(ended.round);
                break;
            }
            case 'CardsDealt': {
                const cardsDealt = msg as GameEvents.RoundCardsDealt;
                GameHandler.dealCards(cardsDealt.cards);
                break;
            }
            case 'Declaration': {
                const declaration = msg as GameEvents.RoundDeclaration;
                GameHandler.declarationMade(declaration.player, declaration.type);
                break;
            }
            case 'RequestDeclaration': {
                const requestDeclaration = msg as GameEvents.RoundRequestDeclaration;
                GameHandler.declarationRequested(requestDeclaration.declarations);
                break;
            }
            case 'StateChange': {
                const stateChange = msg as GameEvents.RoundStateChange;
                break;
            }
            case 'GamemodeSelected': {
                const gamemodeSelected = msg as GameEvents.RoundGamemodeSelected;
                GameHandler.gamemodeSelected(
                    gamemodeSelected.gamemode,
                    gamemodeSelected.player,
                    gamemodeSelected.team
                );
                break;
            }
            case 'BidSelected': {
                const bidSelected = msg as GameEvents.RoundBidSelected;
                GameHandler.bidSelected(
                    bidSelected.player,
                    bidSelected.bid
                );
                break;
            }
            case 'BidRequested': {
                const bidRequested = msg as GameEvents.RoundBidRequested;
                GameHandler.bidRequested(bidRequested.stronger, bidRequested.multiplier);
                break;
            }
        }
    }

    function handleTrickEvent(name: string, msg: any) {
        switch (name) {
            case 'Started':
                const started = msg as GameEvents.TrickStarted;
                gameStore.getGameHandler(msg.uuid)?.trickStarted(started.order);
                break;
            case 'End':
                const ended = msg as GameEvents.TrickEnd;
                gameStore.getGameHandler(msg.uuid)?.trickEnded(ended.handHolder, ended.card);
                break;
            case 'CardPlayed':
                const cardPlayed = msg as GameEvents.TrickCardPlayed;
                gameStore.getGameHandler(msg.uuid)?.cardPlayed(cardPlayed.player, cardPlayed.card);
                break;
            case 'CallToPlay':
                const callToPlay = msg as GameEvents.TrickCallToPlay;
                gameStore.getGameHandler(msg.uuid)?.cardRequested(callToPlay.legalMoves, callToPlay.time_ms);
                break;
        }
    }

    if (ws) {
        ws.close();
    }

    return new Promise<void>((resolve, reject) => {
        if (!auth.accessToken || auth.accessToken.length == 0) {
            reject("Not logged in.");
            return;
        }
        ws = new WebSocket(BACKEND_WS);
        ws.onopen = () => {
            socketReconnect = false;
            const authToken = localStorage.getItem("access_token") || "";
            send({ event: 'User:Login', token: authToken });
            resolve();
        }
        ws.onclose = () => {
            socketReconnect = true;
            socketLogged = false;
            // destroy lobby and game
            lobbies.clear();
            lobby.clearLobby();
            lobby.clearInvitations();
            // gameStore.gameEnded();
            router.push('/');
            reject();
        }

        ws.onerror = (err) => {
            socketReconnect = true;
            reject();
        }

        ws.onmessage = (msg: any) => {
            try {
                msg = JSON.parse(msg.data);
            } catch (error) {
                console.error("failed to parse message", msg.data);
                return;
            }
            console.log("ws received event:[%s]", msg.event);
            handleEvent(msg.event, msg);
        }
    })
}

async function reconnect() {
    try {
        if (ws && ws.readyState == ws.OPEN) {
            throw new Error("WS ALREADY CONNECTED")
        }
        await createWebsocket()
    } catch (err) {
        console.error('WEBSOCKET_RECONNECT: Error', err)
    }
}

setInterval(() => {
    if (socketReconnect) {
        reconnect()
    }
}, 2500);

export function initSocket() {
    reconnect();
}

export function closeSocket() {
    if (ws && ws.readyState == ws.OPEN) {
        ws.close();
    }
    socketReconnect = false;
}