import { defineStore, skipHydrate } from 'pinia'
import { computed, ref, watch } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import * as ws from "@/tools/websocket";
import * as http from "@/tools/http";
import type { profile_t } from '@/server-types';

type extended_profile_t = profile_t & { sentFriendRequests: string[], receivedFriendRequests: string[] }

export const useAuthStore = defineStore('auth', () => {
    const accessToken = useLocalStorage("access_token", '');
    const refreshToken = useLocalStorage("refresh_token", '');

    const profile = ref<extended_profile_t>();
    const googleCode = ref("");

    const loggedIn = computed(() => {
        return refreshToken.value.length > 0
            && refreshTokenInfo.value !== undefined
            && typeof refreshTokenInfo.value.exp === 'number'
            && new Date(refreshTokenInfo.value.exp * 1000) > new Date()
            // access token
            && accessToken.value.length > 0
            && accessTokenInfo.value !== undefined
            && typeof accessTokenInfo.value.exp === 'number'
            && new Date(accessTokenInfo.value.exp * 1000) > new Date()
    });

    const isAdmin = computed(() => {
        return accessTokenInfo.value && accessTokenInfo.value.admin
    })

    const accessTokenInfo = computed(() => {
        try {
            if (accessToken.value == undefined) return undefined;
            return JSON.parse(atob(accessToken.value.split('.')[1]));
        } catch (e) {
            return undefined;
        }
    });

    const refreshTokenInfo = computed(() => {
        try {
            if (refreshToken.value == undefined) return undefined;
            return JSON.parse(atob(refreshToken.value.split('.')[1]));
        } catch (e) {
            return undefined;
        }
    });

    const username = computed(() => {
        return accessTokenInfo.value?.username;
    });

    async function refresh() {
        profile.value = await http.getProfile(accessTokenInfo.value!.username) as extended_profile_t;
    }

    async function handleLoginChanged(loggedIn: boolean) {
        console.log("[AUTH] LOGIN CHANGED ->", loggedIn)
        if (loggedIn) {
            const prof = await http.getProfile(accessTokenInfo.value!.username);
            profile.value = prof;
            ws.initSocket();
        } else {
            accessToken.value = "";
            ws.closeSocket();
            profile.value = undefined;
        }
    }

    function setTokens(refresh: string, access: string) {
        console.log("[AUTH] SET TOKENS", { refresh, access });
        // track
        localStorage.setItem("refresh_backup_"+new Date().getTime(), refresh)
        refreshToken.value = refresh;
        accessToken.value = access;
    }

    function logout() {
        console.log("[AUTH] LOGOUT")
        localStorage.setItem("last_logout_time", String(new Date().getTime()))
        refreshToken.value = "";
        accessToken.value = "";
    }

    async function refreshAccessTokenIfNeeded() {
        if (refreshToken.value == undefined || refreshTokenInfo.value == undefined) {
            console.warn("no refresh token");
            return;
        }

        const now = Date.now();
        const refreshExpDate = new Date(refreshTokenInfo.value.exp * 1000);

        if (now > refreshExpDate.getTime()) {
            // refresh has expired, we have to login again
            console.log("refresh has expired, we have to login again");
            localStorage.setItem("refresh_expired_ts", String(new Date().getTime()))
            refreshToken.value = undefined;
            return;
        }

        if (accessTokenInfo.value == undefined) {
            console.warn("no saved access token, refreshing");
            return await _refreshAccessToken();
        }

        const accessExpDate = new Date(accessTokenInfo.value.exp * 1000);
        const accessIatDate = new Date(accessTokenInfo.value.iat * 1000);

        const middle = new Date((accessExpDate.getTime() - accessIatDate.getTime()) / 2 + accessIatDate.getTime());

        if (now > middle.getTime()) {
            console.warn("access token passed middle, refreshing");
            return await _refreshAccessToken();
        }

    }

    async function _refreshAccessToken() {
        try {
            const resp = await http.refreshAccessToken(refreshToken.value);
            if (typeof resp.access_token === 'string') {
                accessToken.value = resp.access_token;
            }
        } catch (error) {
            console.error("cannot refresh token", error)
        }
    }

    watch(loggedIn, (loggedIn) => {
        handleLoginChanged(loggedIn);
    }, { immediate: true });

    // initial call
    refreshAccessTokenIfNeeded();

    // refreshAccessTokenIfNeeded().then(() => {
    //     ready.value = true;
    //     handleLoginChanged(loggedIn.value);
    // });


    // check access token once every 6 hours
    setInterval(() => {
        refreshAccessTokenIfNeeded();
    }, 1000 * 60 * 60 * 6 /* 6 hours in ms */)

    return {
        accessToken: skipHydrate(accessToken),
        accessTokenInfo, refreshTokenInfo, username, loggedIn, profile, refresh,
        googleCode, setTokens, logout, isAdmin, handleLoginChanged
    }
})