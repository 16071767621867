import { createApp } from 'vue'
//@ts-ignore
import App from './App.vue'
import { router } from './router'
import { createPinia } from 'pinia';
import "./assets/main.scss";
import vue3GoogleLogin from 'vue3-google-login'
import VueApexCharts from "vue3-apexcharts";
import VueTippy from "vue-tippy";

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(VueTippy, {
    defaultProps: {
        arrow: true,
        interactive: true,
        allowHTML: false,
        animation: 'shift-away',
        trigger: 'mouseenter',
        touch: true,
    },
})

app.use(vue3GoogleLogin, {
    clientId: '807611619374-8t882rlcljne4vft3ffujs60fmoo79k4.apps.googleusercontent.com'
});
app.use(VueApexCharts);

app.mount('#app');
