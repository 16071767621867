<template>
    <div class="flex items-center w-full text-neutral-400">
        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg  text-blue-200" :class="{
            'bg-green-800': type === 'success',
            'bg-blue-800': type === 'info',
            'bg-red-800': type === 'error',
            'bg-yellow-800': type === 'warning',
        }">
            <i :class="{
                'fas fa-check': type === 'success',
                'fas fa-info': type === 'info',
                'fas fa-xmark': type === 'error',
                'fas fa-triangle-exclamation': type === 'warning',
            }" />
        </div>

        <div class="ms-3 text-sm font-normal overflow-hidden text-ellipsis">
            <slot />
        </div>
        <button type="button" class="ms-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 inline-flex items-center justify-center
            h-8 w-8 text-neutral-500 hover:text-white bg-neutral-900 hover:bg-neutral-800"
            data-dismiss-target="#toast-default" aria-label="Close" @click="emit('close')" v-if="closeable">
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
    type: "success" | "error" | "warning" | "info",
    closeable?: boolean
}>(), {
    type: "info",
    closeable: true
});

const emit = defineEmits<{
    close: []
}>();
</script>
