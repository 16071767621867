import type { profile_t } from '@/server-types';
import { defineStore } from 'pinia'
import { ref } from 'vue';
import * as http from "@/tools/http";

export const useGeneralStore = defineStore('general', () => {
    const online = ref<Record<string, profile_t>>({});
    const leaderboards = ref<Record<string, Array<[string, number]>>>({});

    async function updateLeaderboards(){
        const list = await http.listLeaderboards();
        for(const name of list){
            leaderboards.value[name] = await http.getLeaderboard(name)
        }
    }

    // one time
    http.getOnline().then((res) => online.value = res);
    updateLeaderboards();

    return { online, leaderboards }
})