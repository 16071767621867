const ORDER_NO_TRUMPS = ['7', '8', '9', 'J', 'Q', 'K', 'X', 'A']
const ORDER_TRUMPS = ['7', '8', 'Q', 'K', 'X', 'A', '9', 'J']
const SUITS = ['C', 'D', 'H', 'S']

export function newDeck() {
    let cards: string[] = [];
    for (const suit of SUITS) {
        for (const label of ORDER_TRUMPS) {
            cards.push(label + suit);
        }
    }
    for (let i = cards.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [cards[i], cards[j]] = [cards[j]!, cards[i]!];
    }
    return cards;
}

function breakdown(a: string): [string, string] {
    return [a.slice(0, 1), a.slice(1)] // rank, suit
}

function getIndex(rank: string, suit: string, trumps: boolean) {
    return SUITS.indexOf(suit) * 100 + (trumps ? ORDER_TRUMPS : ORDER_NO_TRUMPS).indexOf(rank);
}

export function cardComparator(a: string, b: string, suit: string) {
    const cardA = breakdown(a);
    const cardB = breakdown(b);

    if (suit == 'A') {
        return getIndex(...cardB, true) - getIndex(...cardA, true);
    }

    if (suit == 'N') {
        return getIndex(...cardB, false) - getIndex(...cardA, false);
    }

    if (cardA[1] == suit) {
        if (cardB[1] == suit) {
            return getIndex(...cardB, true) - getIndex(...cardA, true);
        }
        return 1;
    }

    if (cardB[1] == suit) {
        return -1;
    }

    return getIndex(...cardB, false) - getIndex(...cardA, false);
}