import { createRouter, createWebHistory } from 'vue-router/auto'
import { useAuthStore } from '@/stores/auth'
import { useMenuStore } from '@/stores/menu'
import { PROD } from '@/constants';
import { useTitle } from '@vueuse/core';
import { useMobile } from '@/helpers/ui';

export const router = createRouter({
    history: createWebHistory(PROD ? "#" : "/"),
});

router.beforeEach((to, from, next) => {
    // always close menu on route change
    const menuStore = useMenuStore();
    menuStore.opened = false;
    menuStore.mobileHeaderMenu.open = false;

    const auth = useAuthStore();
    const loggedIn = auth.loggedIn || Boolean(auth.refreshTokenInfo);

    console.log("BEFORE EACH, to-meta:[%s], loggedIn:[%s]", JSON.stringify(to.meta), loggedIn)

    if (typeof to.name === 'string' && to.name.length > 0 && !to.name.startsWith("/")) {
        useTitle(to.name + " - Belot Masters");
        menuStore.mobileHeaderMenu.title = to.name === 'Home' ? 'Belot Masters' : to.name;
    } else {
        menuStore.mobileHeaderMenu.title = "";
    }

    if (to.meta.requiresAuth) {
        if (!loggedIn) {
            next({ path: "/login" })
            return;
        }

        if (to.meta.requiresAdmin) {
            if (!auth.isAdmin) {
                next({ path: "/" })
                return;
            }
        }
    }

    if (to.meta.requiresNoAuth) {
        if (loggedIn) {
            next({ path: "/" })
            return;
        }
    }

    if (to.name === '/profile/') {
        // redirect to my profile
        next({ name: `/profile/[id]`, params: { id: auth.username } })
    }

    // Settings mobile view only on mobile
    if (
        to.path.endsWith("/settings") &&
        !useMobile.value
    ) {
        next('/settings/profile')
    }

    next();
})

