import type { menu_item_t } from '@/types';
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue';
import { useRoute } from 'vue-router/auto';

const MENU_ITEMS: menu_item_t[] = [{
    icon: 'fas fa-home',
    tooltip: 'Home',
    path: '/'
},
{
    icon: 'fas fa-dice',
    tooltip: 'Play',
    path: '/play'
},
{
    icon: 'fas fa-tv',
    tooltip: 'Replay',
    path: '/replay'
},
{
    icon: 'fas fa-trophy',
    tooltip: 'Leaderboard',
    path: '/leaderboard'
},
{
    icon: 'fas fa-cog',
    tooltip: 'Settings',
    path: '/settings'
},
{
    icon: 'fas fa-code',
    tooltip: 'Debug',
    path: '/debug',
    hide_mobile: true,
    admin: true
}];

export const useMenuStore = defineStore('menu', () => {
    const route = useRoute();

    const menuItems = ref(MENU_ITEMS);
    const hovered = ref(false);
    const selectedIcon = computed(() => {
        return MENU_ITEMS.find(item => {
            if(item.path === '/') return route.path === '/';
            const path = '/' + item.path.slice(1).split("/").at(0);
            return route.path.startsWith(path)
        })?.icon
    })
    const opened = ref(false);

    const mobileHeaderMenu = reactive({
        show: true,
        open: false,
        title: "BelotMasters"
    });

    const notificationsMenu = reactive({
        open: false
    })

    return {
        menuItems, hovered, selectedIcon, opened, mobileHeaderMenu,
        notificationsMenu
    }
})