import { defineStore } from 'pinia';
import * as http from "@/tools/http";
import { ref } from 'vue';
import { useAuthStore } from './auth';
import type { lobby_resp_t, profile_t } from '@/server-types';
import { useLobbiesStore } from './lobbies';
import { useToastStore } from './toast';

export const useLobbyStore = defineStore('lobby', () => {
    const lobbyID = ref(-1);
    const teammate = ref<profile_t>();
    const opponent1 = ref<profile_t>();
    const opponent2 = ref<profile_t>();
    const isAdmin = ref(false);

    const auth = useAuthStore();
    const lobbies = useLobbiesStore();
    const toast = useToastStore();

    const invitingFor = ref<"teammate" | "opponent">();
    const invitations = ref<number[]>([]);

    function receiveInvitation(lobbyId: number) {
        invitations.value.push(lobbyId);
        toast.success("You have a new lobby invitation");
    }

    function removeInvitation(lobbyId: number) {
        const index = invitations.value.indexOf(lobbyId);
        if (index > -1) {
            invitations.value.splice(index, 1)
        }
    }

    function clearInvitations() {
        invitations.value.length = 0;
    }

    async function update() {
        const mylobbyID = await http.getMyLobbyID();
        if (mylobbyID == undefined || typeof mylobbyID !== 'number' || mylobbyID < 0) {
            return;
        }
        lobbyID.value = mylobbyID;
        const lobby: lobby_resp_t = await http.getLobbyByID(mylobbyID);

        const username = auth.username;

        const { teams } = lobby;
        const { teamA, teamB } = teams;
        const creator = teams.teamA[0];
        isAdmin.value = (username === creator);

        const myTeam = teamA.includes(username) ? teamA : teamB;
        const otherTeam = teamA.includes(username) ? teamB : teamA;

        // Update teammate
        if (myTeam[0] == username) {
            if (myTeam[1] != undefined && (teammate.value == undefined || teammate.value.username !== myTeam[1])) {
                teammate.value = await http.getProfile(myTeam[1])
            }
        } else {
            if (myTeam[0] != undefined && (teammate.value == undefined || teammate.value.username !== myTeam[0])) {
                teammate.value = await http.getProfile(myTeam[0])
            }
        }

        // Update opponent1
        if (otherTeam[0] != undefined && (opponent1.value == undefined || opponent1.value.username !== otherTeam[0])) {
            opponent1.value = await http.getProfile(otherTeam[0]);
        }

        // Update opponent2
        if (otherTeam[1] != undefined && (opponent2.value == undefined || opponent2.value.username !== otherTeam[1])) {
            opponent2.value = await http.getProfile(otherTeam[1]);
        }
    }

    async function createLobby() {
        await http.createLobby();
        await update()
    }

    async function addBot(teamId: 'A' | 'B') {
        await http.addBot(lobbyID.value, teamId);
        await update()
    }

    async function startLobby(points: number) {
        if (lobbyID.value > -1) {
            await http.startLobby(lobbyID.value, points);
            clearLobby();
            lobbies.onLobbyDeleted(lobbyID.value)
        }
    }

    async function deleteLobby() {
        if (lobbyID.value > -1) {
            await http.deleteLobby(lobbyID.value);
            clearLobby();
        }
    }

    async function leaveLobby() {
        if (lobbyID.value > -1) {
            await http.leaveLobby(lobbyID.value);
            clearLobby();
        }
    }

    function clearLobby() {
        lobbyID.value = -1;
        teammate.value = undefined;
        opponent1.value = undefined;
        opponent2.value = undefined;
        invitingFor.value = undefined;
    }

    async function veryQuickGame() {
        await createLobby();
        await addBot('B')
        await addBot('B')
        await addBot('A')
        await startLobby(38);
    }

    update();

    return {
        addBot, teammate, opponent1, opponent2,
        lobbyID, isAdmin, createLobby, deleteLobby, startLobby, update,
        invitingFor, receiveInvitation, clearInvitations, invitations,
        clearLobby, removeInvitation, veryQuickGame, leaveLobby
    };
});