<template>
    <MobileMenu v-if="useMobile" />
    <DesktopMenu v-else />

    <MobileHeader v-if="useMobile && menuStore.mobileHeaderMenu.show" />
    <MobileHeaderMenu />
    <NotificationsMenu />

    <BasicLayout>
        <div :class="layoutClass">
            <slot />
        </div>
    </BasicLayout>
</template>

<script setup lang="ts">
import BasicLayout from './BasicLayout.vue';
import DesktopMenu from "@/components/menu/DesktopMenu.vue";
import MobileHeader from '@/components/menu/MobileHeader.vue';
import MobileHeaderMenu from '@/components/menu/MobileHeaderMenu.vue';
import NotificationsMenu from '@/components/menu/NotificationsMenu.vue';
import MobileMenu from "@/components/menu/MobileMenu.vue";
import { useMobile } from "@/helpers/ui";
import { useMenuStore } from '@/stores/menu';
import { computed } from 'vue';

const menuStore = useMenuStore();

const layoutClass = computed(() => {
    if(useMobile.value){
        // bootom menu
        const arr = ['mb-16']; 
        // upper menu
        if(menuStore.mobileHeaderMenu.show){
            arr.push('mt-12')
        }
        return arr;
    }
    return ['ml-16', '2xl:mr-16']
})

</script>

