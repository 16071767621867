import { notifcation_type_t, toast_t } from '@/types';
import { useLocalStorage } from '@vueuse/core';
import { defineStore, skipHydrate } from 'pinia'
import { ref } from 'vue';

export const useToastStore = defineStore('toast', () => {
    const toasts = ref<toast_t[]>([]);
    const allToasts = useLocalStorage<toast_t[]>('allToasts', [])

    function addToast(message: string, type: notifcation_type_t = "info") {
        const toast: toast_t = {
            message,
            visible: true,
            type,
            ts: Date.now()
        };

        allToasts.value.unshift(toast);
        if(allToasts.value.length > 15){
            allToasts.value.pop();
        }
        toasts.value.push(toast);
        setTimeout(() => {
            toasts.value.shift();
        }, 7500);
    }

    function success(message: string) {
        addToast(message, 'success');
    }

    function info(message: string) {
        addToast(message, 'info');
    }

    function warning(message: string) {
        addToast(message, 'warning');
    }

    function error(message: string) {
        addToast(message, 'error');
    }

    return {
        toasts,
        allToasts: skipHydrate(allToasts),
        addToast,
        success, info, warning, error
    }
})