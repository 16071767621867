<template>
    <div v-if="authStore.loggedIn">
        <div class="fixed top-0 left-0 w-screen h-full z-[60] bg-black/70" @click="menuStore.mobileHeaderMenu.open = false"
            v-show="menuStore.mobileHeaderMenu.open">
        </div>
        <Transition name="slide-fade">
            <div class="absolute top-0 left-0 h-full w-[max(65vw,240px)] bg-neutral-900 flex flex-col gap-2 p-4
            overflow-x-hidden overflow-y-hidden divide-y divide-neutral-700 z-[61]"
                v-show="menuStore.mobileHeaderMenu.open">

                <div class="flex flex-col gap-1 pb-3" v-if="profile">
                    <router-link to="/profile">
                        <figure class="w-12 h-12 aspect-square">
                            <img :src="profile.profilePictureUrl" class="rounded">
                        </figure>
                    </router-link>
                    <div class="space-x-1">
                        <span class="text-base font-semibold">{{ profile.username }}</span>
                        <span class="text-xs text-neutral-400">({{ profile.elo }})</span>
                    </div>
                    <div class="space-x-1">
                        <span class="text-base">{{ profile.friends.length }}</span>
                        <span class="text-xs text-neutral-400">Friends</span>
                        <span class="text-base">0</span>
                        <span class="text-xs text-neutral-400">Online</span>
                    </div>
                </div>

                <div class="py-5 flex flex-col gap-3 font-semibold">
                    <router-link to="/profile">
                        <div class="space-x-4 flex flex-row">
                            <div class="w-6">
                                <i class="fas fa-user"></i>
                            </div>
                            <span>Profile</span>
                        </div>
                    </router-link>

                    <div class="space-x-4 flex flex-row">
                        <div class="w-6">
                            <i class="fas fa-users"></i>
                        </div>
                        <span>Friends</span>
                    </div>
                </div>

                <div class="py-5 flex flex-col gap-3 font-semibold">
                    <router-link to="/settings">
                        <div class="space-x-4 flex flex-row">
                            <div class="w-6">
                                <i class="fas fa-gear"></i>
                            </div>
                            <span>Settings</span>
                        </div>
                    </router-link>
                </div>

            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import { useMenuStore } from '@/stores/menu';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const menuStore = useMenuStore();

const { profile } = storeToRefs(authStore)

</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 250ms ease-in;
}

.slide-fade-leave-active {
    transition: all 100ms ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(-240px);
    opacity: 0.6;
}
</style>