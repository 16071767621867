import * as constants from '@/constants';
import type { archive_entry_t, archive_game_t, game_table_entry_t, lobby_resp_t, profile_t } from '@/server-types';
import { useLocalStorage } from '@vueuse/core';

export async function fetchApi<T = any>(path: string, props?: { body?: object, method?: string }) {
    const body = props?.body ? JSON.stringify(props.body) : undefined;
    const method = props?.method ?? "GET";

    let res;
    let headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };

    const auth_token = useLocalStorage("access_token", "").value;
    if (auth_token) {
        headers['Authorization'] = `Bearer ${auth_token}`
    }

    try {
        res = await fetch(constants.BACKEND_HTTP + path, {
            method,
            headers,
            body
        });
    } catch (error) {
        console.error("Error fetching BE", constants.BACKEND_HTTP + path, body, error)
        throw new Error("Something went processing the response of the server.");
    }
    if (res.ok) {
        if (res.status == 204) {
            return { _empty: true } as T
        }

        const resp_text = await res.text();
        
        if(import.meta.env.DEV){
            console.debug("[DEV] HTTP_RESP url:[%s] code:[%s] body:[%s]", constants.BACKEND_HTTP + path, res.status, resp_text)
        }

        if(!resp_text || resp_text.length == 0){
            return {_empty: true} as T;
        }

        try {
            return JSON.parse(resp_text) as T;
        } catch (error) {
            throw new Error("Cannot parse response");
        }
    } else if (res.status == 403) {
        console.error("server returned 403")
        throw new Error("Please login to complete that action.");
    }

    let errorMessage: string | undefined;

    try {
        const resp_text = await res.text();
        const parsed = JSON.parse(resp_text);
        if (parsed.error) {
            errorMessage = String(parsed.error);
        } 
    } catch (error) {
        throw new Error("Bad response from the server.");
    }

    throw new Error(errorMessage || "Bad response from the server.");
}

interface login_resp_t {
    access_token: string,
    refresh_token: string,
    user: profile_t,
}

export const login = (username: string, password: string) =>
    fetchApi<login_resp_t>('/api/user/login', { method: 'POST', body: { username, password } });

export const register = (username: string, email: string, password: string) =>
    fetchApi<login_resp_t>('/api/user/register', { method: 'POST', body: { username, email, password } });

export const refreshAccessToken = (refresh_token: string) =>
    fetchApi('/api/user/refresh', { method: 'POST', body: { refresh_token } });


export const getProfile = (username: string) => fetchApi<profile_t>('/api/profile/' + username);

// BEGIN: Lobby 
export const getMyLobbyID = () => fetchApi<{ lobbyID: number }>("/api/lobby").then((res) => res.lobbyID);
export const getLobbyByID = (id: number) => fetchApi<lobby_resp_t>(`/api/lobby/${id}/list`);
export const createLobby = () => fetchApi('/api/lobby/', { method: "PUT" });
export const addBot = (id: number, teamId: 'A' | 'B') => fetchApi(`/api/lobby/${id}/add-bot/${teamId}`);
export const startLobby = (id: number, points: number) => fetchApi(`/api/lobby/${id}/start?points=${points}`);
export const deleteLobby = (id: number) => fetchApi('/api/lobby/' + id, { method: "DELETE" });
export const leaveLobby = (id: number) => fetchApi(`/api/lobby/${id}/leave`);
export const sendLobbyInvitation = (id: number, username: string, side: 'teammate' | 'opponent') => fetchApi(`/api/lobby/${id}/invite/${side}/${username}`)
export const acceptLobbyInvitation = (id: number) => fetchApi(`/api/lobby/${id}/accept`)
export const listLobbies = () => fetchApi<Record<number, lobby_resp_t>>(`/api/lobby/list`);
export const joinLobby = (id: number, teamId: 'A' | 'B') => fetchApi(`/api/lobby/${id}/join/${teamId}`);
// END: Lobby 

export const getOnline = () => fetchApi('/api/profile/online')

export const sendFriendRequest = (username: string) => fetchApi(`/api/user/friends/send-request/${username}`);
export const acceptFriendRequest = (username: string) => fetchApi(`/api/user/friends/accept-request/${username}`);
export const rejectFriendRequest = (username: string) => fetchApi(`/api/user/friends/reject-request/${username}`);
export const removeFriend = (username: string) => fetchApi(`/api/user/friends/${username}`, { method: "DELETE" });

export const changePassword = (old_password: string, new_password: string) => fetchApi('/api/user/change-password', {
    method: "POST",
    body: { old_password, new_password }
})

export const changeProfilePictureUrl = (url: string) => fetchApi('/api/user/change-profile-picture-url', {
    method: "POST",
    body: { url }
})

export const sendCredentialsFromGoogle = (code: string) => fetchApi('/api/user/login-with-third-party/google', {
    method: "POST",
    body: { code }
})

export const checkUsername = (username: string) => fetchApi('/api/user/check-username/' + username);
export const selectUsernameGoogle = (username: string, code: string) =>
    fetchApi<login_resp_t>('/api/user/login-with-third-party/google/select-username', {
        method: "POST",
        body: { username, code }
    })

// Leaderboards
export const listLeaderboards = () => fetchApi<string[]>('/api/leaderboard/list');
export const getLeaderboard = (name: string) => fetchApi<Array<[string, number]>>('/api/leaderboard/' + name);

// Charts
export const getRatingHistory = (username: string) => fetchApi<[number,number][]>(`/api/profile/${username}/history/elo`);

// Past games
export const getGameData = (id: string) => fetchApi<archive_game_t>(`/api/games/data/${id}`);
export const getGameInfo = (id: string) => fetchApi<game_table_entry_t>(`/api/games/info/${id}`);
export const getRecentGameUUIDs = () => fetchApi<string[]>(`/api/games/recent`);

// Live games
export const getLiveGames = () => fetchApi<Omit<game_table_entry_t, 'game' | 'endedTs'>[]>(`/api/games/live`);