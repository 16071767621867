<template>
    <div class="fixed top-0 left-0 h-screen w-16 m-0 flex flex-col bg-neutral-900 shadow-lg z-50">
        <template v-for="item in menuItems">
            <MenuItem :key="item.icon" :icon="item.icon" :tooltip="item.tooltip" :path="item.path"
                v-if="!item.admin || (item.admin && authStore.isAdmin)" />
        </template>

        <div style="margin-top: auto" class="flex flex-col">
            <MenuItem v-if="authStore.loggedIn" tooltip="My Profile" icon="fas fa-user" path="/profile" />
            <MenuItem v-else tooltip="Login" icon="fas fa-user" path="/login" />
            <MenuItem tooltip="Sign out" icon="fas fa-sign-out-alt" path="/login" v-if="authStore.loggedIn"
                @click="logout" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useMenuStore } from "@/stores/menu";
import MenuItem from "@/components/menu/MenuItem.vue";
import { useAuthStore } from '@/stores/auth';
import { useRouter } from "vue-router/auto";
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modal";

const menuStore = useMenuStore();
const modalStore = useModalStore();
const { menuItems } = storeToRefs(menuStore);
const router = useRouter();

const authStore = useAuthStore();

function logout() {
    modalStore.showModal("Are you sure you want to logout?", () => {
        authStore.logout();
        setTimeout(() => {
            router.push("/login");
        }, 200)
    }, () => { })
}
</script>

