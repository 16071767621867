export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/index.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/debug',
    name: 'Debug',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/debug.vue'),
    /* no children */
    meta: {
      "requiresAuth": true,
      "requiresAdmin": true
    }
  },
  {
    path: '/edit-profile',
    name: 'Edit Profile',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/edit-profile.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/leaderboard.vue'),
    /* no children */
  },
  {
    path: '/live',
    /* internal name: '/live' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/live/[id]',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/live/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/login.vue'),
    /* no children */
    meta: {
      "requiresNoAuth": true
    }
  },
  {
    path: '/new-play',
    name: '/new-play',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/new-play.vue'),
    /* no children */
  },
  {
    path: '/play',
    name: '/play',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/play.vue'),
    /* no children */
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/privacy-policy.vue'),
    /* no children */
  },
  {
    path: '/profile',
    /* internal name: '/profile' */
    /* no component */
    children: [
      {
        path: '',
        name: '/profile/',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/profile/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/profile/[id]',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/profile/[id].vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      }
    ],
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/register.vue'),
    /* no children */
    meta: {
      "requiresNoAuth": true
    }
  },
  {
    path: '/replay',
    /* internal name: '/replay' */
    /* no component */
    children: [
      {
        path: '',
        name: '/replay/',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/replay/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/replay/[id]',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/replay/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/select-username',
    name: 'Register',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/select-username.vue'),
    /* no children */
    meta: {
      "requiresNoAuth": true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/settings.vue'),
    children: [
      {
        path: '',
        name: 'Settings',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/settings/index.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      },
      {
        path: 'cards',
        name: 'Cards',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/settings/cards.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      },
      {
        path: 'legal',
        name: 'Legal',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/settings/legal.vue'),
        /* no children */
      },
      {
        path: 'password',
        name: 'Change password',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/settings/password.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      },
      {
        path: 'profile',
        name: 'Edit Profile',
        component: () => import('/var/services/belotmasters.com/frontend/src/pages/settings/profile.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      }
    ],
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/tos',
    name: 'Terms of Service',
    component: () => import('/var/services/belotmasters.com/frontend/src/pages/tos.vue'),
    /* no children */
  }
]
