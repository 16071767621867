<template>
    <div class="h-16 bg-neutral-900 text-neutral-200 fixed left-0 bottom-0 flex flex-row justify-between w-screen z-50">
        <template v-for="item in menuItems" :key="item.icon">
            <MenuItem v-if="!item.hide_mobile" :icon="item.icon" :tooltip="item.tooltip" :path="item.path" />
        </template>
    </div>
</template>

<script lang="ts" setup>
import { useMenuStore } from '@/stores/menu';
import { storeToRefs } from 'pinia';
import MenuItem from "@/components/menu/MenuItem.vue";

const menuStore = useMenuStore();
const { menuItems } = storeToRefs(menuStore);
</script>