import { defineStore } from "pinia";
import { ref } from "vue";
import * as http from "@/tools/http";
import { InternalLobby } from "@/types";

export const useLobbiesStore = defineStore('lobbies', () => {
    const lobbies = ref<Record<number, InternalLobby>>({});

    function onLobbyCreated(id: number, isPublic: boolean) {
        lobbies.value[id] = {
            id,
            teams: {
                teamA: [],
                teamB: []
            },
            public: isPublic
        };
    }

    function onLobbyDeleted(lobbyId: number) {
        delete lobbies.value[lobbyId];
    }

    async function onPlayerAdded(lobbyId: number, teamId: 'A' | 'B', username: string) {
        console.log("onPlayerAdded", lobbyId, username, teamId)
        const profile = await http.getProfile(username);
        
        lobbies.value[lobbyId].teams[('team' + teamId) as 'teamA' | 'teamB'].push(profile);
    }

    function onPlayerRemoved(lobbyId: number, teamId: 'A' | 'B', username: string) {
        let team = lobbies.value[lobbyId].teams[('team' + teamId) as 'teamA' | 'teamB'];
        team = team.filter((player) => player.username !== username);
    }

    function clear(){
        lobbies.value = {}
    }

    // run once
    http.listLobbies().then((listLobbies) => {
        for (const lobby of Object.values(listLobbies)) {
            onLobbyCreated(lobby.id, lobby.public);
            for (const player of lobby.teams.teamA) if (player) {
                onPlayerAdded(lobby.id, 'A', player)
            }
            for (const player of lobby.teams.teamB) if (player) {
                onPlayerAdded(lobby.id, 'B', player)
            }
        }
    });


    return {
        lobbies, onLobbyCreated, onLobbyDeleted, onPlayerAdded, onPlayerRemoved, clear
    }

})