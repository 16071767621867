<template v-if="icon != undefined">
    <span class="relative flex items-center justify-center h-12 w-12 my-2 mx-auto 
            bg-neutral-800 text-primary-500 hover:bg-primary-600 hover:text-white
            rounded-3xl hover:rounded-xl transition-all ease-in cursor-pointer duration-200" :class="{
                'rounded-xl': selectedIcon == icon
            }" @click="clicked">
        <span class="icon ">
            <i :class="icon"></i>
        </span>
    </span>
</template>

<script setup lang="ts">
import { router } from "@/router";
import { useMenuStore } from "@/stores/menu";
import { storeToRefs } from "pinia";

interface Props {
    icon: string,
    tooltip: string,
    path: string,
    ignoreClick?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    ignoreClick: false
});

const emit = defineEmits<{
    (e: 'click'): void
}>();

const menuStore = useMenuStore();
const { selectedIcon } = storeToRefs(menuStore)

function clicked() {
    emit('click');
    if (props.ignoreClick) {
        return;
    }
    router.push(props.path);
};
</script>
