import { GameEvents, archive_entry_t, bid_gamemode_t, declaration_t, exp_declaration_t, profile_t } from "@/server-types";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import * as http from "@/tools/http"
import * as ws from "@/tools/websocket"
import { position } from "@/types";
import { cardComparator } from "@/tools/cards";

interface zone_t {
    username: string,
    profile?: profile_t,
    cards: string[],
    playableCards?: string[],
    message?: string,
    playedCard?: string
}

interface played_card_t {
    value: string,
    position: position,
    zIndex: number
}

interface game_t {
    points: [number, number], // [A, B]
    me: {
        username: string,
        team: 'A' | 'B',
    }
    currentMove: zone_k,
    summary?: archive_entry_t,
    gamemode: {
        gamemode: bid_gamemode_t,
        player: string,
        team: "A" | "B"
    },
    requiredPoints: number,
    declarations: exp_declaration_t[],
    eloRange?: GameEvents.GameStarted['eloRange'],
    bids?: {
        stronger: bid_gamemode_t[],
        multiplier?: 'contre' | 'recontre'
    },
    order: zone_k[],
    zones: Record<zone_k, zone_t>,
    playedCards: played_card_t[],
    gameEndedModalData?: {
        outcome: GameEvents.GameEnded['outcome'],
        oldRating: number,
        newRating: number
    }
}

type zone_k = 'east' | 'west' | 'north' | 'south';

function getDefaultOrder(): zone_k[] {
    return [
        'south',
        'west',
        'north',
        'east'
    ]
}

const BID_MAP: Record<bid_gamemode_t | 'contre' | 'recontre', string> = {
    'P': 'Pass',
    'C': 'Clubs',
    'D': 'Diamonds',
    'H': 'Hearts',
    'S': 'Spades',
    'N': 'No Trumps',
    'A': 'All Trumps',
    'contre': 'Contre',
    'recontre': 'Recontre'
}

/* ---------------------------------------------
 * The game is a loop of rounds until one team reaches the required points to win (by default 151).
 * Each round is split into 2 logical stages: Bidding & Playing
 * If the bidding stage is successful, then we move to the Playing stage; Bidding -> Playing
 * The playing stage is a loop of 8 Tricks
 * A trick is the stage of the game when all the players play a card.
 * The player with the strongest hand collects all the points from the table.
 * After all 8 tricks finish, the round finishes and the next starts (if there isn't a game winner).
 * ----------------------------------------------*/

export const useGameStore = defineStore('game', () => {
    const games = ref<Record<string, game_t>>({});
    const myUuid = ref<string>();
    
    const myGame = computed(() => {
        if(myUuid.value){
            return games.value[myUuid.value];
        }
    })

    function getGameHandler(uuid: string) {
        const game = games.value[uuid];

        if (!game) return;

        return {
            // Game Events
            gameEnded(modalData?: {
                outcome: GameEvents.GameEnded['outcome'],
                oldRating: number,
                newRating: number
            }) {
                if(modalData && modalData.newRating !== -1){
                    game.gameEndedModalData = modalData;
                }
            },

            updatePoints(pointsA: number, pointsB: number) {
                game.points = [pointsA, pointsB];
            },
            // Round Events

            roundStarted() {
                // clear messages & played cards
                for (const zone in game.zones) {
                    game.zones[zone as zone_k] = {
                        cards: [],
                        username: game.zones[zone as zone_k].username,
                        profile: game.zones[zone as zone_k].profile
                    }
                }
                // close summary
                game.summary = undefined;
            },

            roundEnded(summary: archive_entry_t) {
                game.summary = summary;
                game.gamemode = {
                    gamemode: "P",
                    player: game.me.username,
                    team: game.me.team,
                }
            },

            dealCards(cards: string[] | number) {
                const length = typeof cards === 'number' ? cards : cards.length;

                game.zones.east.cards.push(...new Array(length).fill('back'));
                game.zones.west.cards.push(...new Array(length).fill('back'));
                game.zones.north.cards.push(...new Array(length).fill('back'));
                if(typeof cards === 'number'){
                    game.zones.south.cards.push(...new Array(length).fill('back'));
                } else {
                    game.zones.south.cards.push(...cards);
                    game.zones.south.cards.sort((a, b) => cardComparator(a, b, game.gamemode.gamemode || "A"));
                }
            },

            declarationMade(player: string, declaration: declaration_t) {
                const zone = this._getZoneByUsername(player);
                if (!zone) return;
                this._addZoneMessage(zone, declaration)
            },


            declarationRequested(declarations: exp_declaration_t[]) {
                game.declarations = declarations;
            },

            declarationFulfilled(declaration: exp_declaration_t) {
                ws.submitDeclaration(declaration);
                game.declarations.length = 0;
            },

            gamemodeSelected(gamemode: bid_gamemode_t, player: string, team: "A" | "B") {
                game.gamemode = { gamemode, player, team };
            },

            // Bidding events

            bidSelected(player: string, bid: bid_gamemode_t | 'contre' | 'recontre') {
                const zone = this._getZoneByUsername(player);
                if (!zone) {
                    return;
                }
                this._addZoneMessage(zone, "Bid " + (BID_MAP[bid] ?? bid));
            },

            bidRequested(stronger: bid_gamemode_t[], multiplier?: 'contre' | 'recontre') {
                game.bids = {
                    ...game.bids,
                    stronger,
                }
                game.bids!.multiplier = multiplier;
            },

            fulfillBidRequest(gamemode: bid_gamemode_t | 'contre' | 'recontre') {
                game.bids = undefined;
                ws.sendBidSelected(gamemode);
            },

            // Trick events

            trickStarted(order: string[]) {
                this._clearPlayedAndPlayableCards();
                game.order = order.map((username) => this._getZoneNameByUsername(username)!);
            },

            cardRequested(legalMoves: string[], time_ms: number) {
                game.zones.south.playableCards = legalMoves;
            },

            cardFulfilled(card: string) {
                ws.sendCardPlayed(card);
            },

            cardPlayed(player: string, card: string) {
                console.log("card played", game)
                const zone = this._getZoneByUsername(player);
                console.log("card played", zone)
                if (!zone) return;

                game.order.shift();
                zone.playedCard = card;
                if (zone.cards.some(card => card === 'back')) {
                    zone.cards.pop();
                } else {
                    zone.cards = zone.cards.filter(c => c !== card)
                }
                zone.playableCards = undefined;
            },

            trickEnded(handholder: string, card: string) {
                this._clearPlayedAndPlayableCards();
            },

            // Helpers

            _getZoneByUsername(username: string) {
                for (const name in game.zones) {
                    const zone = game.zones[name as zone_k];
                    if (zone.username === username) {
                        return zone;
                    }
                }
            },

            _getZoneNameByUsername(username: string): zone_k | undefined {
                for (const name in game.zones) {
                    const zone = game.zones[name as zone_k];
                    if (zone.username === username) {
                        return name as zone_k;
                    }
                }
            },

            _addZoneMessage(zone: zone_t, message: string) {
                zone.message = message;
                setTimeout(() => {
                    zone.message = "";
                }, 2500)
            },

            _clearPlayedAndPlayableCards() {
                for (const name in game.zones) {
                    const zone = game.zones[name as zone_k];
                    zone.playedCard = undefined;
                    zone.playableCards = undefined;
                }
            },
        }
    }

    // Game Events
    function gameStarted(
        uuid: string,
        username: string,
        team: 'A' | 'B',
        order: string[],
        requiredPoints: number,
        eloRange?: GameEvents.GameStarted['eloRange']
    ) {
        myUuid.value = uuid;

        const index = order.indexOf(username);
        const prevIndex = index == 0 ? 3 : index - 1;
        const nextIndex = index == 3 ? 0 : index + 1;
        const teammateIndex = index + (index < 2 ? 2 : - 2);

        const game: game_t = {
            points: [0, 0],
            me: {
                username,
                team,
            },
            zones: {
                'east': {
                    username: order[prevIndex],
                    cards: [],
                    playedCard: '7c'
                },
                'west': {
                    username: order[nextIndex],
                    cards: [],
                    playedCard: '8c'
                },
                'north': {
                    username: order[teammateIndex],
                    cards: [],
                    playedCard: '9c'

                },
                'south': {
                    username: order[index],
                    cards: [],
                    playedCard: '10c'

                }
            },
            currentMove: 'south',
            declarations: [],
            eloRange,
            requiredPoints,
            gamemode: {
                gamemode: "P",
                player: username,
                team: team
            },
            playedCards: [],
            order: getDefaultOrder()
        }

        // load profiles async
        http.getProfile(order[nextIndex]).then(prof =>
            game.zones.west.profile = prof);

        http.getProfile(order[prevIndex]).then(prof =>
            game.zones.east.profile = prof);

        http.getProfile(order[teammateIndex]).then(prof =>
            game.zones.north.profile = prof);

        http.getProfile(order[index]).then(prof =>
            game.zones.south.profile = prof);

        games.value[uuid] = game;
    }

    return {
        myUuid,
        myGame,
        games,
        gameStarted,
        getGameHandler
    } as const;
});
