<template>
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <figure class="w-full max-w-[20rem] h-auto aspect-square my-[-20%]">
            <img src="/android-chrome-512x512.png" alt="Belot Masters logo">
        </figure>
    </div>
    
    <div class="absolute bottom-8 left-1/2 -translate-x-1/2">
        <div class="flex flex-row gap-2">
            <div class="w-3 h-3 rounded-full bg-primary-600 animate-[bounce_1s_infinite]"></div>
            <div class="w-3 h-3 rounded-full bg-primary-600 animate-[bounce_1s_infinite_150ms]"></div>
            <div class="w-3 h-3 rounded-full bg-primary-600 animate-[bounce_1s_infinite_300ms]"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
</script>