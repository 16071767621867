import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useModalStore = defineStore('modal', () => {
    const visible = ref(false);
    const modalText = ref("");
    const yes_cb = ref(() => {});
    const no_cb = ref(() => {});

    function showModal(text: string, on_yes: () => void, on_no: () => void){
        modalText.value = text;
        visible.value = true;
        yes_cb.value = on_yes;
        no_cb.value = on_no;
    }

    return { visible, modalText, yes_cb, no_cb, showModal }
})