<template>
    <header class="w-full h-12 bg-neutral-900 fixed top-0 left-0 shadow z-10">
        <div class="absolute left-3 top-1/2 -translate-y-1/2" @click="menuStore.mobileHeaderMenu.open = true">
            <i class="fas fa-bars m-auto"></i>
        </div>

        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <span class="font-semibold">{{ title }}</span>
        </div>

        <div class="absolute top-1/2 right-2 -translate-y-1/2 h-8 w-8" @click="menuStore.notificationsMenu.open = true">
            <div class="absolute top-1/2 -translate-y-1/2 right-2 ">
                <i class="fas fa-bell m-auto"></i>
            </div>
            <span
                class="absolute top-1 right-[10px] p-1 translate-x-1/2 bg-red-500 rounded-full text-xs text-white"
                v-if="toastStore.allToasts.length">
            </span>
        </div>
    </header>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import { useMenuStore } from '@/stores/menu';
import { useToastStore } from '@/stores/toast';
import { computed } from 'vue';

const auth = useAuthStore();
const menuStore = useMenuStore();
const toastStore = useToastStore()

const title = computed(() => {
    return menuStore.mobileHeaderMenu.title || 'Belot Masters';
})
</script>

<style>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>