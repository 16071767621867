<template>
    <div class="fixed right-4 top-2 max-w-[264px] w-[264px] mt-2 space-y-2 z-10">
        <ToastBase v-for="(toast, index) in toasts" :type="toast.type" class="select-none z-[99] notification"
            v-show="toast.visible" :key="toast.message + index" @close="toast.visible = false">  {{ toast.message }}
        </ToastBase>
    </div>
</template>

<script setup lang="ts">
import ToastBase from './ToastBase.vue';
import { useToastStore } from "@/stores/toast";
import { storeToRefs } from "pinia";

const toastStore = useToastStore();
const { toasts } = storeToRefs(toastStore);
</script>

<style scoped>
.notification {
    transition: right 2s, top 4s;
    animation: expand 0.5s forwards ease-out;
}

@keyframes expand {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>