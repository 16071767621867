<template>
    <div class="fixed top-0 left-0 w-screen h-screen bg-neutral-800">
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div class="flex flex-col items-center gap-1 text-center">
                <figure class="w-full max-w-[20rem] h-auto aspect-square my-[-20%]">
                    <img src="/android-chrome-512x512.png" alt="Belot Masters logo">
                </figure>
                <span class="font-bold text-xl">Belot Masters</span>
                <span class="text-neutral-400">You are currently offline.</span>
            </div>
        </div>
    </div>
</template>