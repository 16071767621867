import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { ref } from 'vue';

export const breakpoints = useBreakpoints(breakpointsTailwind);
export const useMobile = breakpoints.smaller('lg');

export const rotateFactor = ref(6);
export const translateFactor = ref(15);

export function getGamemodeName(g: string) {
    switch (g) {
        case 'C':
            return 'Suit Clubs';
        case 'D':
            return 'Suit Diamonds';
        case 'H':
            return 'Suit Hearts';
        case 'S':
            return 'Suit Spades';
        case 'N':
            return 'No Trumps';
        case 'A':
            return 'All Trumps';
        default:
            return g;
    }
}

export function getDeclarationName(declaration: string){
    switch(declaration){
        case 'P':
            return 'Pass';
        default:
            return getGamemodeName(declaration);
    }
}

export const sizes = [
    'xs', 'sm', 'md', 'lg', 'xl'
] as const;

export type size_t = typeof sizes[number];

export function getSizeIndex(size: string) {
    return Math.max(sizes.indexOf(size as size_t), 0); // default to 'md'
}